import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
// import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


export const Youtube = () => {
    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {
        setShowMore(!showMore); // Toggle the visibility of the additional row
    };

    const videos = [
        {
            id: 1,
            link: "https://www.youtube.com/embed/hKfwhFLnxsE?si=SY5qMwZLQMo7xQXj",
            imgSrc: "https://img.youtube.com/vi/hKfwhFLnxsE/hqdefault.jpg",
            title: "How can Smart IG help with your SAP S/4HANA migration?",
        },
        {
            id: 2,
            link: "https://www.youtube.com/embed/eMEaeE65jH0?si=2NAVZga6Thf1MlE1",
            imgSrc: "https://img.youtube.com/vi/eMEaeE65jH0/hqdefault.jpg",
            title: "Data Management and Governance in Digital Transformations",
        },

        // {
        //     id: 3,
        //     link: "https://youtu.be/xF7ePlwf4hw?si=Ntm2zbxGUl5vzkMp",
        //     imgSrc: "https://img.youtube.com/vi/xF7ePlwf4hw/hqdefault.jpg",
        //     title: "Can any organization truly be 100% data-driven?",
        // },
        {
            id: 4,
            link: "https://youtu.be/ngaFaea0A2c?si=i49B7nt_RbDa5PxW",
            imgSrc: "https://img.youtube.com/vi/ngaFaea0A2c/hqdefault.jpg",
            title: "Ready to Transform through RISE with SAP? Let Us Guide Your Journey.",
        },
        {
            id: 5,
            link: "https://youtu.be/eXW63fTthhM?si=q32KcBzmTCDYpGRD",
            imgSrc: "https://img.youtube.com/vi/eXW63fTthhM/hqdefault.jpg",
            title: "Transform Your Business with Data Migration Experts!",
        },
        {
            id: 6,
            link: "https://www.youtube.com/watch?v=B8dbqbC_M9E",
            imgSrc: "https://img.youtube.com/vi/B8dbqbC_M9E/hqdefault.jpg",
            title: "Why Automation is Crucial in Data Migration?",
        },
        {
            id: 7,
            link: "https://www.youtube.com/watch?v=1HPk3cjvtiU?si=Y-bz_dpBimjLIauT",
            imgSrc: "https://img.youtube.com/vi/1HPk3cjvtiU/hqdefault.jpg",
            title: "Why does data migration fail?",
        },  


    ];

    const hiddenVideos = [
       
        {
            id: 8,
            link: "https://www.youtube.com/watch?v=h1ckcxAvhfQ?si=NRF4hRtxDKYUvCsf",
            imgSrc: "https://img.youtube.com/vi/h1ckcxAvhfQ/hqdefault.jpg",
            title: "What is Smart IG?",
        },
        {
            id: 9,
            link: "https://www.youtube.com/watch?v=KbUQj6bPJSg?si=ukaVN71bZxKyq4ZD",
            imgSrc: "https://img.youtube.com/vi/KbUQj6bPJSg/hqdefault.jpg",
            title: "Have data migration challenges remained the same over time?",
        },
        {
            id: 10,
            link: "https://www.youtube.com/watch?v=cGhhr_mw8mk?si=QCRnm0Oz1PGOS-Y4",
            imgSrc: "https://img.youtube.com/vi/cGhhr_mw8mk/hqdefault.jpg",
            title: "What are the top 3 data migration challenges?",
        },
        {
            id: 11,
            link: "https://www.youtube.com/watch?v=BkBpkettD8s?si=07FPQ3y8_qFgLNjq",
            imgSrc: "https://img.youtube.com/vi/BkBpkettD8s/hqdefault.jpg",
            title: "Simplifying the Data Migration with SmartIG",
        },
        {
            id: 12,
            link: "https://www.youtube.com/watch?v=dSEoxDgC7gM?si=8eoY4mnF7wAKWCZr",
            imgSrc: "https://img.youtube.com/vi/dSEoxDgC7gM/hqdefault.jpg",
            title: "Insights",
        },
        {
            id: 13,
            link: "https://www.youtube.com/watch?v=c1hWcQTWKQk?si=F82rtQ65v7-CtUeM",
            imgSrc: "https://img.youtube.com/vi/c1hWcQTWKQk/hqdefault.jpg",
            title: "Sunburst",
        },
        {
            id: 14,
            link: "https://www.youtube.com/watch?v=XVk4rv1x0Uw",
            imgSrc: "https://img.youtube.com/vi/XVk4rv1x0Uw/hqdefault.jpg",
            title: "Gradient Stacked Chart",
        },
    ];

    return (
        <div>


            <Helmet>
                <title>AI Powered  Data Analytics and Visualization Tool - Smart IG  </title>

                <meta name="description" content="SmartIG provides embedded AI solutions for data integration, migration, and analytics, transforming data into strategic insights for business success." />

                <meta name="author" content="Smart InteGraphics" />

                <meta name="robots" content="index, follow" />

                {/* Og Tags */}

                <meta property="og:title" content="AI Powered  Data Analytics and Visualization Tool - Smart IG " />

                <meta property="og:description" content="SmartIG provides embedded AI solutions for data integration, migration, and analytics, transforming data into strategic insights for business success." />

                {/* <meta property="og:image" content={homebanner} /> */}

                <meta property="og:url" content="https://smartintegraphics.com/youtube-videos" />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}

                <meta name="twitter:title" content="AI Powered  Data Analytics and Visualization Tool - Smart IG " />

                <meta name="twitter:description" content="SmartIG provides embedded AI solutions for data integration, migration, and analytics, transforming data into strategic insights for business success." />

                {/* <meta name="twitter:image" content={homebanner} /> */}
            </Helmet>

            <section className="youtube-main-sec">
                <Container >
                    <Row className="text-center d-flex align-items-center justify-content-center media-hero">
                        <div className="youtube-main">
                            <p>YOUTUBE VIDEOS</p>
                        </div>
                        <p>
                            Unlock the business value of your data with an end-to-end tool that <br />
                            extracts, integrates, cleans and visualizes your data.
                        </p>

                    </Row>
                    <Row style={{ position: 'relative', height: '0px' }}>
                        <img src={require('../assets/Pricing/Img13.png')} className="ciricle-big-1" alt="youtube-ico-1" />
                    </Row>

                    <Row style={{ position: 'relative', height: '0px' }}>
                        <img src={require('../assets/Pricing/Img13.png')} className="ciricle-big-2" alt="youtube-ico-1" />
                    </Row>

                </Container>
            </section>

            <section>
                <Container>
                    <Row className="youtube-main-video text-center">
                        <p>
                            LATEST VIDEO
                        </p>
                    </Row>
                    <Row style={{ height: '0px' }}>
                        <img src={require('../assets/Pricing/Img5.png')} className="youtu-big-ico" alt="youtube" />
                    </Row>

                    <Row className="align-items-center justify-content-center py-5">
                        <Col lg={10}>
                            <div className="">
                                {/* <video  controls style={{borderRadius: '8px'}} className="main-video-latest-utu">
                            <source src={require('../assets/Media/Rise-SAP.mp4')} type="video/mp4" />
                        </video> */}
                            </div>

                            <Link to='https://youtu.be/JNqPeA6vnGc?si=U-A20L2MvydaN1Z-'>
                                <div className="youtube-main-outer">
                                    <iframe style={{ borderRadius: '15px' }} width="100%" height="100%" src="https://www.youtube.com/embed/JNqPeA6vnGc?si=U-A20L2MvydaN1Z-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                </div>
                            </Link>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="bg-light py-2">
                <Container>
                    <Row>
                        {videos.map((video) => (
                            <Col lg={4} className="mt-4" key={video.id}>
                                <div className="yout-arr-outer">
                                    <div className="you-inner-ig">
                                        <a href={video.link} target="_blank" rel="noopener noreferrer">
                                            <img src={video.imgSrc} alt={`Video thumbnail for ${video.title}`} className="thumb-utube" />
                                        </a>
                                    </div>
                                    <div className="you-co-car">
                                        <h4>{video.title}</h4>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>


                    <div className="text-center mt-2">
                        {showMore && (
                            <Row className="text-start mt-4">
                                {hiddenVideos.map((video) => (
                                    <Col lg={4} className="mt-4" key={video.id}>
                                        <div className="yout-arr-outer">
                                            <div className="you-inner-ig">
                                                <a href={video.link} target="_blank" rel="noopener noreferrer">
                                                    <img src={video.imgSrc} alt={`Video thumbnail for ${video.title}`} className="thumb-utube" />
                                                </a>
                                            </div>
                                            <div className="you-co-car">
                                                <h4>{video.title}</h4>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        )}

                        <Button onClick={handleShowMore} className="show-more-btn" >
                            <div className="btn-inner">
                                {showMore ? (<KeyboardDoubleArrowUpIcon className="up-arrow" />) : (<KeyboardDoubleArrowDownIcon className="down-arrow" />)}
                            </div>
                        </Button>
                    </div>
                </Container>
            </section>
        </div>
    )
}