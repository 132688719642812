import React, { useState } from "react";
import { Button, Col, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import './Pricing.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export const Pricing = () => {
    const [key, setKey] = useState('data-integration');
    return (
        <div>

            <Helmet>
                <title>Data Integration & Analytics: Basic to Enterprise Solutions </title>

                <meta name="description" content="Smart Integraphics offers scalable, cost-effective data analytics, migration, and integration solutions with flexible pricing tiers for any business need." />

                <meta name="author" content="Smart InteGraphics" />

                <meta name="robots" content="index, follow" />

                {/* Og Tags */}

                <meta property="og:title" content="Data Integration & Analytics: Basic to Enterprise Solutions" />

                <meta property="og:description" content="Smart Integraphics offers scalable, cost-effective data analytics, migration, and integration solutions with flexible pricing tiers for any business need." />

                {/* <meta property="og:image" content={homebanner} /> */}

                <meta property="og:url" content="https://smartintegraphics.com/pricing" />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}

                <meta name="twitter:title" content="Data Integration & Analytics: Basic to Enterprise Solutions" />

                <meta name="twitter:description" content="Smart Integraphics offers scalable, cost-effective data analytics, migration, and integration solutions with flexible pricing tiers for any business need." />

                {/* <meta name="twitter:image" content={homebanner} /> */}
            </Helmet>

            <section className="py-3">
                <Container>
                    <Row className="text-center pricing-main">
                        <Row className="text-center align-items-center justify-content-center">

                            <div className="about-2">
                                <p>
                                    Price
                                </p>
                            </div>


                        </Row>
                        <h2 className="py-3 ">
                            Investment In Your  Future
                        </h2>
                        <p>
                            Unlock the business value of your data with an end-to-end tool  that extracts,<br /> integrates , clean and visualizes your data.
                        </p>
                    </Row>

                    <Row className="empty-ic-1">
                        <img src={require('../assets/Pricing/Img17.png')} className="price-icon-1" alt="icon-1" />
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="price-data-head" >
                                <Tab eventKey="data-integration" title="Data Integration">

                                    {/* <Row>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>BASIC</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $49.99/$65.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>

                                                    <p className="pice-user">
                                                        1 User
                                                    </p>

                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Files
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    XLS
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    XLSX
                                                                </p>
                                                            </div>

                                                            <p>
                                                           <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    CSV
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    JSON
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    DataBase
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Oracle
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Ms Access
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SQL Lite
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Mongo DB
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    IBM netezza
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Teradata
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    ERP
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Online Services
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    X
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Youtube
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Facebook
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    LinkedIn
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>


                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>PROFESSIONAL</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $79.99/$99.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        3 Users
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Files
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    XLS
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    XLSX
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    CSV
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    JSON
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    DataBase
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Oracle
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Ms Access
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SQL Lite
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Mongo DB
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    IBM netezza
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Teradata
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    ERP
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Online Services
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    X
                                                                </p>
                                                            </div>

                                                            <p>
                                                                Limited(1)
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Youtube
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Facebook
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    LinkedIn
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>




                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <div className="">
                                                        <h2>ENTERPRISE</h2>
                                                        <p style={{ fontSize: '0.8vw', fontWeight: '600' }}>(On-Cloud/On-Premise)</p>
                                                    </div>

                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $99.99/$119.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        10 Users
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Files
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    XLS
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    XLSX
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    CSV
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    JSON
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    DataBase
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Oracle
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Ms Access
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SQL Lite
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Mongo DB
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    IBM netezza
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Teradata
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    ERP
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Online Services
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    X
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Youtube
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    Facebook
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                <p>
                                                                    LinkedIn
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Contact Sales
                                                        </Button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </Col>

                                    </Row> */}


                                    <Row className="pricing-page-1-table">
                                        <Table bordered >
                                            <thead>
                                                <tr className="text-center table-head-price">
                                                    <th></th>
                                                    <th className="price-main-price">
                                                        <p className="price-title-basic"> BASIC  </p>
                                                        <p className="head-price-sec-table">
                                                            $49.99(Year)/<br />$65.99(Month)
                                                        </p>
                                                        
                                                        <p>
                                                           
                                                            1 User
                                                        </p>
                                                    </th>
                                                    <th className="price-main-price">
                                                        <p className="price-title-basic">
                                                            PROFESSIONAL
                                                        </p>
                                                        <p className="head-price-sec-table">
                                                            $79.99(Year)/<br />$99.99(Month)
                                                            </p>
                                                        
                                                        <p>
                                                          
                                                            3 Users
                                                        </p>

                                                    </th>

                                                    <th className="price-main-price">
                                                        <p className="price-title-basic">ENTERPRISE<br />(On-Cloud)</p>
                                                        <p className="head-price-sec-table">
                                                            $99.99(Year)/<br />$119.99(Month)
                                                        </p>
                                                        
                                                        <p>
                                                            
                                                            10 Users
                                                        </p>
                                                    </th>

                                                    <th className="price-main-price">
                                                        <p className="price-title-basic">ENTERPRISE<br />(On-Premise)</p>
                                                        {/* <p className="head-price-sec-table">
                                                            $99.99(Year)/<br />$119.99(Month)</p> */}
                                                        
                                                        <p>
                                                            
                                                            10 Users
                                                        </p>

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={5} className="main-head-table-sub-sec">Files</td>

                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">XLS</td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">XLSX</td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">
                                                        CSV
                                                    </td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">JSON</td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className="main-head-table-sub-sec">DataBase</td>

                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Oracle</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">MS SQL</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr"> MY SQL</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">IBM DB2</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Maria Database</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Ms Access</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">SQL Lite</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">SQL Server</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Postgre SQL</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">Mongo DB</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">IBM netezza</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Teradata</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Azure SQL</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={5} className="main-head-table-sub-sec">ERP</td>

                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">SAP ECC</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">SAP HANA</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Oracle ERP</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">MS Dynamics</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className="main-head-table-sub-sec">Online Services</td>

                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">X</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Youtube</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Facebook</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">LinkedIn</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Contact Sales
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>

                                </Tab>

                                <Tab eventKey="data-migration" title="Data Migration">
                                    {/* <Row>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>BASIC</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $49.99/$65.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>

                                                    <p className="pice-user">
                                                        1 User
                                                    </p>

                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p className="text-start">
                                                                    Oracle
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MS Access
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p className="text-start">
                                                                    SQLite
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MonogoDB
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    IBM Netezza
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    TeraData
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>PROFESSIONAL</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $79.99/$99.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        3 Users
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p className="text-start">
                                                                    Oracle
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MS Access
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p className="text-start">
                                                                    SQLite
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MonogoDB
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    IBM Netezza
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    TeraData
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center  mt-4">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <div className="">
                                                        <h2>ENTERPRISE</h2>
                                                        <p style={{ fontSize: '0.8vw', fontWeight: '600' }}>(On-Cloud/On-Premise)</p>
                                                    </div>

                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $99.99/$119.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        10 Users
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p className="text-start">
                                                                    Oracle
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MS Access
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p className="text-start">
                                                                    SQLite
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    MonogoDB
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    IBM Netezza
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    TeraData
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Contact Sales
                                                        </Button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </Col>

                                    </Row> */}

                                    <Row>
                                        <Table bordered >
                                            <thead>
                                                <tr className="text-center table-head-price">
                                                    <th></th>
                                                    <th className="price-main-price">
                                                        <p className="price-title-basic">BASIC</p>
                                                        <p className="head-price-sec-table">
                                                            $49.99(Year)/<br />$65.99(Month)
                                                        </p>
                                                        <p>
                                                            1 User
                                                        </p>


                                                    </th>

                                                    <th className="price-main-price">
                                                        <p className="price-title-basic">PROFESSIONAL
                                                        </p>
                                                        <p className="head-price-sec-table">
                                                            $79.99(Year)/<br />$99.99(Month)
                                                        </p>
                                                        <p>
                                                            3 Users
                                                        </p>


                                                    </th>

                                                    <th className="price-main-price">
                                                        <p className="price-title-basic">
                                                            ENTERPRISE<br />(On-Cloud)
                                                        </p>
                                                        <p className="head-price-sec-table">
                                                            $99.99(Year)/<br/>$119.99(Month)
                                                        </p>
                                                        <p>
                                                            10 Users
                                                        </p>


                                                    </th>

                                                    <th className="price-main-price">
                                                        <p className="price-title-basic" >ENTERPRISE <br />
                                                        (On-Premise)</p>
                                                        {/* <p className="head-price-sec-table">
                                                            $99.99(Year)/<br/>$119.99(Month)
                                                        </p> */}
                                                        <p>
                                                            10 Users
                                                        </p>


                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td className="sub-tit-table-integr">SAP ECC</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">SAP HANA</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">
                                                        Oracle ERP
                                                    </td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">MS Dynamics</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>


                                                <tr>
                                                    <td className="sub-tit-table-integr">Oracle</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">MS SQLL</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr"> MY SQL</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">IBM DB2</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Maria Database</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Ms Access</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">SQL Lite</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">SQL Server</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Postgre SQL</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">Mongo DB</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">IBM netezza</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Teradata</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Azure SQL</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Contact Sales
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>


                                <Tab eventKey="data-analytics" title="Data Analytics" >

                                    {/* <Row>
                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>BASIC</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $49.99/$65.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>

                                                    <p className="pice-user">
                                                        1 User
                                                    </p>

                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                16
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    DashBoards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                2
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Data pre-processing
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p className="text-start">
                                                                    Auto Suggestion Charts (AI Driven)
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Download Dashboard as PDF
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Advanced Data Preparation
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Data Quering
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Refresh Rate
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Fine tune on Built-in Algorithms
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Personalized dashboards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p className="text-start">
                                                                    Drill Down and Customize Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Self-service Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    AI Generated Trends
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Vice-Activated Querying
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Smart Data Discovering
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Automated Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Visualization From Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Natural Language Questions
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Contexual Analysis
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    AI Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Mail Notification
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Mobile App
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    iMirAI[co-pilot]
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>PROFESSIONAL</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $79.99/$99.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        3 Users
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                80
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    DashBoards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                10
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                <p>
                                                                    Refresh Rate
                                                                </p>
                                                            </div>
                                                            <p>
                                                                Hourly
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Data pre-processing
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Advanced Data Preparation
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p className="text-start">
                                                                    Auto Suggestion Charts (AI Driven)
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Data Quering
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Personalized dashboards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    iMirAI[co-pilot]
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Download Dashboard as PDF
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>



                                                    

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Fine tune on Built-in Algorithms
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>



                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p className="text-start">
                                                                    Drill Down and Customize Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Self-service Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    AI Generated Trends
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Vice-Activated Querying
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Smart Data Discovering
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Automated Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Visualization From Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Natural Language Questions
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Contexual Analysis
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    AI Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Mail Notification
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>



                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Mobile App
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <div className="">
                                                        <h2>ENTERPRISE</h2>
                                                        <p style={{ fontSize: '0.8vw', fontWeight: '600' }}>(On-Cloud/On-Premise)</p>
                                                    </div>

                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $99.99/$119.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        10 Users
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                200
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    DashBoards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                25
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Refresh Rate
                                                                </p>
                                                            </div>
                                                            <p>
                                                                As Nedded
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Data pre-processing
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Advanced Data Preparation
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p className="text-start">
                                                                    Auto Suggestion Charts (AI Driven)
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Data Quering
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Fine tune on Built-in Algorithms
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Personalized dashboards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p className="text-start">
                                                                    Drill Down and Customize Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Self-service Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    AI Generated Trends
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Vice-Activated Querying
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Smart Data Discovering
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Automated Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Visualization From Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Natural Language Questions
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Contexual Analysis
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    AI Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Mail Notification
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Download Dashboard as PDF
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    Mobile App
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">

                                                                <p>
                                                                    iMirAI[co-pilot]
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Contact Sales
                                                        </Button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row> */}

                                    <Row>
                                        <Table bordered>
                                            <thead>
                                                <tr className="text-center table-head-price">
                                                    <th></th>
                                                    <th className="price-main-price">
                                                        <p className="price-title-basic">BASIC </p>
                                                        <p className="head-price-sec-table"> $49.99(year)/<br />$65.99(Month)</p>

                                                        <p>
                                                            1 User
                                                        </p>


                                                    </th>

                                                    <th className="price-main-price">
                                                        <p className="price-title-basic">PROFESSIONAL </p>
                                                        <p className="head-price-sec-table">$79.99(year)/<br />$99.99(Month)</p>
                                                        <p>
                                                            3 Users
                                                        </p>

                                                    </th>

                                                    <th className="price-main-price">
                                                        <p className="price-title-basic" >ENTERPRISE<br /> (On-Cloud)</p>
                                                        <p className="head-price-sec-table">
                                                            $99.99(Year)/<br /> $119.99(Month)
                                                        </p>

                                                        <p>

                                                            10 Users
                                                        </p>


                                                    </th>

                                                    <th className="price-main-price">
                                                        <p className="price-title-basic">ENTERPRISE <br /> (On-Premise)</p>
                                                        {/* <p className="head-price-sec-table">
                                                            $99.99(Year)/<br /> $119.99(Month)
                                                        </p> */}

                                                        <p>
                                                            10 Users
                                                        </p>


                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td className="sub-tit-table-integr">Charts</td>
                                                    <td className="inner-tick-ico">16</td>
                                                    <td className="inner-tick-ico">80</td>
                                                    <td className="inner-tick-ico">200</td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">DashBoards</td>
                                                    <td className="inner-tick-ico">2</td>
                                                    <td className="inner-tick-ico">10</td>
                                                    <td className="inner-tick-ico">25</td>
                                                </tr>


                                                <tr>
                                                    <td className="sub-tit-table-integr">
                                                        Data pre-processing
                                                    </td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Auto Suggestion Charts (AI Driven)</td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>


                                                <tr>
                                                    <td className="sub-tit-table-integr">Download Dashboard as PDF</td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Refresh Rate</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">Hourly</td>
                                                    <td className="inner-tick-ico">As Nedded</td>
                                                    <td className="inner-tick-ico">As Nedded</td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">iMirAI[co-pilot]</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Advanced Data Preparation</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Data Quering</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Personalized dashboards</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                {/* <tr>
                                                    <td className="sub-tit-table-integr">Defying correlation</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr> */}
                                                <tr>
                                                    <td className="sub-tit-table-integr">Fine tune on Built-in Algorithms</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">Drill Down and Customize Charts</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Self-service Analytics</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">AI Generated Trends</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Vice-Activated Querying</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Smart Data Discovering</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Automated Insights</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="sub-tit-table-integr">Visualization From Insights</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">Natural Language Questions</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">Contexual Analysis</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">Predictive Analytics</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">AI Predictive Analytics</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">Mail Notification</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>

                                                <tr>
                                                    <td className="sub-tit-table-integr">Mobile App</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                                <Button className="price-buy-now" >
                                                                    Contact Sales
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>

                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>

                    <Row className="empty-ic-2">
                        <img src={require('../assets/Pricing/Img2.png')} className="price-icon-2" alt="icon-2" />
                    </Row>
                </Container>
            </section>

        </div>
    )
}