import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './imirai.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import image1 from '../assets/imirai/Screenshot1.png';
import image2 from '../assets/imirai/Screenshot2.png';
import image3 from '../assets/imirai/Screenshot3.png';
import image4 from '../assets/imirai/Screenshot4.png';
import image5 from '../assets/imirai/Screenshot5.png';
import { Helmet } from "react-helmet";


export const ImirAI = () => {
    const [activeTab, setActiveTab] = useState('oil&gas');
    const tabCycleRef = useRef(null);

    const tabs = [
        { id: 'oil&gas', imgSrc: image1 },
        { id: 'manufacturing', imgSrc: image2 },
        { id: 'cement', imgSrc: image3 },
        { id: 'chemical', imgSrc: image4 },
        { id: 'energy', imgSrc: image5 },
    ];

    const tabChange = () => {
        const currentIndex = tabs.findIndex(tab => tab.id === activeTab);
        const nextIndex = (currentIndex + 1) % tabs.length;
        setActiveTab(tabs[nextIndex].id);
    };

    useEffect(() => {
        tabCycleRef.current = setInterval(tabChange, 5000);
        return () => clearInterval(tabCycleRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const handleTabClick = (tabId) => {
        clearInterval(tabCycleRef.current);
        setActiveTab(tabId);
        tabCycleRef.current = setInterval(tabChange, 5000);
    };

    const handleMouseEnter = () => {
        clearInterval(tabCycleRef.current);
    };

    const handleMouseLeave = () => {
        tabCycleRef.current = setInterval(tabChange, 5000);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Adjust based on the number of tabs you want to show
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 7000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div>
            <Helmet>
                <title>Smart IG's Copilot iMirAI: Advanced AI Analytics & Insights </title>

                <meta name="description" content="Smart IG’s iMirAI combines AI and advanced analytics to provide real-time insights and simplified data, enhancing decision-making for better business outcomes." />

                <meta name="author" content="Smart InteGraphics" />

                <meta name="robots" content="index, follow" />

                {/* Og Tags */}

                <meta property="og:title" content="Smart IG's Copilot iMirAI: Advanced AI Analytics & Insights" />

                <meta property="og:description" content="Smart IG’s iMirAI combines AI and advanced analytics to provide real-time insights and simplified data, enhancing decision-making for better business outcomes." />

                {/* <meta property="og:image" content={homebanner} /> */}

                <meta property="og:url" content="https://smartintegraphics.com/iMirAI" />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}

                <meta name="twitter:title" content="Smart IG's Copilot iMirAI: Advanced AI Analytics & Insights" />

                <meta name="twitter:description" content="Smart IG’s iMirAI combines AI and advanced analytics to provide real-time insights and simplified data, enhancing decision-making for better business outcomes." />

                {/* <meta name="twitter:image" content={homebanner} /> */}
            </Helmet>


            <section className="imirai-main-sec">
                <Container>
                    <Row style={{ position: 'relative', height: '0px' }}>
                        <img src={require('../assets/Pricing/Img15.png')} className="imirai-big-1" alt="imirair" />
                    </Row>
                    <Row className="text-center">
                        <div className="imir-ma">
                            <p>
                                IMIRAI
                            </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center row">
                            
                            <Col lg={12}>
                            <p className="imir-ai-head-logo-co">
                            <img src= {require('../assets/imirai/MirAI-LOGO-07.png')} style={{width: '5rem', marginRight: '10px'}} alt="mirai-logo"/>
                        Your Smart Copilot, Empowering Every Decision in Data Analytics.
                        </p>
                            </Col>

                       
                        </div>

                        

                        <p style={{ fontWeight: '500' }}>
                            iMirAI leverages cutting-edge artificial intelligence to deliver advanced analytics,<br />
                            intuitive insights, and
                            natural language processing (NLP) capabilities.<br/>

                       

                        </p>

                    </Row>
                    <Row style={{ position: 'relative', height: '0px' }}>
                        <img src={require('../assets/Pricing/Img15.png')} className="imirai-small-1" alt="imirair" />
                    </Row>
                </Container>
            </section>

            <section>
                <Container>

                    <Row className="text-center p-4">
                        <div className="imir-ma">
                            <p>
                                Why Choose iMirAI
                            </p>
                        </div>
                        <p style={{ fontWeight: '500' }}>
                            Choosing iMirAI means opting for a unique solution that revolutionizes your approach to <br /> data analytics. Here are key reasons to consider iMirAI
                        </p>
                    </Row>

                    <Row>
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <div className='iMirAi-points'>
                                <h4>User-Friendly Interface</h4>
                                <p>Designed for ease of use, iMirAI makes data analysis accessible for everyone, regardless of their expertise level.</p>

                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <div className='iMirAi-points'>
                                <h4>Data Simplification</h4>
                                <p> We transform intricate data sets into clear, visual representations, enabling users to grasp insights quickly.</p>

                            </div>
                        </Col><Col lg={4} md={6} sm={12} xs={12}>
                            <div className='iMirAi-points'>
                                <h4>Real-Time Insights</h4>
                                <p>Our AI-driven analytics deliver real-time insights, allowing for timely and informed decision-making.</p>

                            </div>
                        </Col><Col lg={4} md={6} sm={12} xs={12}>
                            <div className='iMirAi-points'>
                                <h4>Enhanced Decision-Making</h4>
                                <p>With advanced analytics at your fingertips, iMirAI supports smarter and faster decisions that can propel your business forward.</p>

                            </div>
                        </Col><Col lg={4} md={6} sm={12} xs={12}>
                            <div className='iMirAi-points'>
                                <h4>Comprehensive Analytics</h4>
                                <p>iMirAI offers robust analytics capabilities that cater to a wide range of business needs, from operational to strategic.</p>

                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <div className='iMirAi-points'>
                                <h4>AI-Powered Automation</h4>
                                <p>Automate routine tasks and focus on what matters most—driving your business success.</p>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="bg-light pt-3 pb-5">
                <Container>
                    <Row className="py-3">
                        <p className="imir-1-sec-co">
                            This innovative platform allows users to interact effortlessly
                            with data through
                            NLP-driven questionnaires, enabling them to extract meaningful information
                        </p>
                    </Row>

                    <Row className="">
                        <div className="cycle-tab-container px-5  align-items-center justify-content-center" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <Slider {...settings} className="nav nav-tabs slider-main-cont">
                                {tabs.map((tab) => (
                                    <div key={tab.id} className={`cycle-tab-item ${activeTab === tab.id ? 'active' : ''}`}>
                                        <a href className="nav-link  inner-of-imirai" role="tab" onClick={() => handleTabClick(tab.id)} >
                                            <div className="tab-icon">
                                                <img src={tab.imgSrc} className="tool-img-inner-co" alt={tab.label} loading='lazy' />
                                            </div>

                                        </a>
                                    </div>
                                ))}
                            </Slider>

                        </div>
                    </Row>
                </Container>
            </section>
        </div>
    )
}