import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './dataintegration.css';
import bigico1 from '../assets/Pricing/Img16.png';
import { Helmet } from "react-helmet";

export const Dataintegration = () => {
    return (
        <div>
             <Helmet>
                <title> Data Integration Consulting Services & Solutions - Smart IG </title>

                <meta name="description" content="Smart Integraphics provides top-tier Data Integration services, collecting and consolidating data from various sources for valuable insights and business growth." />

                <meta name="author" content="Smart InteGraphics" />

                <meta name="robots" content="index, follow" />

                {/* Og Tags */}

                <meta property="og:title" content="Data Integration Consulting Services & Solutions - Smart IG." />

                <meta property="og:description" content="Smart Integraphics provides top-tier Data Integration services, collecting and consolidating data from various sources for valuable insights and business growth." />

                {/* <meta property="og:image" content={homebanner} /> */}

                <meta property="og:url" content="https://smartintegraphics.com/data-integration" />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}

                <meta name="twitter:title" content="Data Integration Consulting Services & Solutions - Smart IG." />

                <meta name="twitter:description" content="Smart Integraphics provides top-tier Data Integration services, collecting and consolidating data from various sources for valuable insights and business growth." />

                {/* <meta name="twitter:image" content={homebanner} /> */}
            </Helmet>



            <section className="data-integratin-main">
                <Container>
                    <Row style={{ position: 'relative', height: '0px' }}>
                        <img src={bigico1} className="integration-small" alt="integration-small" loading="lazy" />
                    </Row>
                    <Row className="text-center d-flex align-items-center justify-content-center media-hero">
                        <div className="data-integration-hero-sec">
                            <p>DATA INTEGRATION</p>
                        </div>
                        <p>
                            In today's era of abundant data, businesses gather information from <br />diverse sources, including sales, operations, and customer interactions.
                        </p>
                    </Row>
                    <Row style={{ position: 'relative', height: '0px' }}>
                        <img src={bigico1} className="integration-big-ico" alt="integration-2" loading="lazy"/>
                    </Row>
                </Container>
            </section>

            <section className="data-integratin-sec-2 bg-light">
                <Container>
                    <Row className=" align-items-center justify-content-center">
                        <Col lg={6}>
                            <img src={require('../assets/data-integration/Data Data Integration-01.png')} className="w-75" alt="data-integration" loading="lazy" />
                        </Col>
                        <Col lg={6}>
                            <p>
                                Data Integration services play a crucial role in collecting, refining, and centralizing this data for easy analysis.
                            </p>
                            <p>
                                This organized data serves as the cornerstone
                                for informed decision-making, trend identification,
                                and operational enhancement, all of which are
                                indispensable for competitiveness and growth
                                in the modern business environment.
                            </p>
                            <p>
                                Data Integration services simplify the conversion  of data into valuable insights, making them a vital asset for any data-driven organization.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section className="py-3">
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={4}>
                            <div className="seamless-out">
                                <div className="seamless-inner-iamge">

                                </div>
                                <div className="seamless-cont">
                                    <h6>
                                        Seamless Integration Across Top Databases
                                    </h6>
                                    <p>
                                        Ingest data from all your databases for  unified data access and improved analytics capabilities
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="seamless-out">
                                <div className="seamless-inner-iamge">

                                </div>
                                <div className="seamless-cont">
                                    <h6>
                                        Supported File Formats for Seamless Ingestion.
                                    </h6>
                                    <p>
                                        Effortlessly integrate data to empower data integration and analytics capabilities across your organization.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className="pt-3 pb-3">
                <Container className="seamles-cilent-imag-1">
                    <Row className="text-center pt-3">
                        <h3 className="sealmes-head">
                            Databases
                        </h3>
                        <p>
                            Structured data from relational databases (like MySQL, Oracle, and SQL Server)<br />
                            and NoSQL databases (like MongoDB and Cassandra) that store critical business information.
                        </p>
                    </Row>
                    <Row className="cilent-ico-sec mx-4">
                        <img src={require('../assets/data-integration/seamless-integration/mysql.png')} style={{ width: '20vh', position: 'relative' }} alt="mysal" />
                        <img src={require('../assets/data-integration/seamless-integration/sqllite.png')} style={{ width: '20vh' }} alt="sql-lite" />
                        <img src={require('../assets/data-integration/seamless-integration/mssql-2.webp')} style={{ width: '20vh' }} alt="mysql" />
                        <img src={require('../assets/data-integration/seamless-integration/azure-sql.png')} style={{ width: '24vh' }} alt="azure-sql" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/Apache-Cassandra-Not-Only-SQL.png')} style={{ width: '20vh' }} alt="cassandra" />
                        <img src={require('../assets/data-integration/seamless-integration/ibmdb2.png')} style={{ width: '20vh' }} alt="ibm" />
                        <img src={require('../assets/data-integration/seamless-integration/postgral.png')} style={{ width: '22vh' }} alt="postgral" />

                        {/*  */}

                        <img src={require('../assets/data-integration/seamless-integration/acess.jfif')} style={{ width: '18vh' }} alt="access" />
                        <img src={require('../assets/data-integration/seamless-integration/teradata.png')} style={{ width: '20vh' }} alt="tearadata" />
                        <img src={require('../assets/data-integration/seamless-integration/oracle-2.png')} style={{ width: '20vh' }} alt="oracle" />
                        <img src={require('../assets/data-integration/seamless-integration/Mongodb.png')} style={{ width: '20vh' }} alt="mongodb" />
                        <img src={require('../assets/data-integration/seamless-integration/MariaDB.png')} style={{ width: '20vh' }} alt="maria" />
                        <img src={require('../assets/data-integration/seamless-integration/ibm-informax.png')} style={{ width: '20vh' }} alt="ibm-informix" />
                        <img src={require('../assets/data-integration/seamless-integration/sybase.png')} style={{ width: '20vh' }} alt="sybase" />
                    </Row>

                    {/* <Row className="cilent-ico-sec pb-5">
                        <img src={require('../assets/data-integration/seamless-integration/acess.jfif')} style={{ width: '12vh' }} alt="access" />
                        <img src={require('../assets/data-integration/seamless-integration/teradata.png')} style={{ width: '14vh' }} alt="tearadata" />
                        <img src={require('../assets/data-integration/seamless-integration/oracle-2.png')} style={{ width: '15vh' }} alt="oracle" />
                        <img src={require('../assets/data-integration/seamless-integration/Mongodb.png')} style={{ width: '18vh' }} alt="mongodb" />
                        <img src={require('../assets/data-integration/seamless-integration/MariaDB.png')} style={{ width: '16vh' }} alt="maria" />
                        <img src={require('../assets/data-integration/seamless-integration/ibm-informax.png')} style={{ width: '16vh' }} alt="ibm-informix" />
                        <img src={require('../assets/data-integration/seamless-integration/sybase.png')} style={{ width: '13vh' }} alt="sybase" />

                    </Row> */}
                </Container>
            </section>

            <section className="py-1">
                <Container className="supported-files-outer">
                    <Row className="text-center pt-3">
                        <h3 className="enterprise-integra">
                            Enterprise Resource Planning
                            (ERP) Systems:
                        </h3>
                        <p>
                            Comprehensive data from ERP solutions (such as SAP, Oracle ERP, and Microsoft Dynamics)<br />
                            that manage core business processes, including finance, HR, and supply chain.
                        </p>
                    </Row>
                    <Row className="support-files-icoo ">
                        <img src={require('../assets/data-integration/DAtabase-Icons/1.png')} style={{ width: '28vh' }} alt="sap-s4" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/icon-sap-ecc.png')} style={{ width: '26vh' }} alt="sap-ece" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/Dynamics.png')} style={{ width: '26vh' }} alt="Microsoft-Dynamics" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/oracle-e-business.png')} style={{ width: '26vh' }} alt="Oracle-e" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/IFS.png')} style={{ width: '22vh' }} alt="IFS" />

                        {/* <img src={require('../assets/data-integration/supported-file-type/csv.png')} style={{ width: '9%' }} alt="csv" />
                        <img src={require('../assets/data-integration/supported-file-type/xls.png')} style={{ width: '10%' }} alt="csv" />
                        <img src={require('../assets/data-integration/supported-file-type/xslx.png')} style={{ width: '10%' }} alt="csv" />
                        <img src={require('../assets/data-integration/supported-file-type/xml.png')} style={{ width: '9%' }} alt="csv" />
                        <img src={require('../assets/data-integration/supported-file-type/txt.png')} style={{ width: '10%' }} alt="csv" />
                        <img src={require('../assets/data-integration/supported-file-type/json.jpg')} style={{ width: '10%' }} alt="csv" />
                        <img src={require('../assets/data-integration/supported-file-type/image.png')} style={{ width: '11%' }} alt="csv" /> */}
                    </Row>
                </Container>
            </section>

            <section className="py-2">
                <Container className="files-integra">
                    <Row className="text-center pt-3">
                        <h3 className="file-inte-mhea">
                            Files
                        </h3>
                        <p>
                            Unstructured and semi-structured data from various file formats <br />
                            (like CSV, Excel, JSON, and XML) that contain important information for analysis.
                        </p>
                    </Row>
                    <Row className="file-img-sec py-3">
                        <img src={require('../assets/data-integration/DAtabase-Icons/csv.png')} style={{ width: '20vh' }} alt="csv" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/x-cel.png')} style={{ width: '20vh' }} alt="XCel" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/XLS-File.png')} style={{ width: '24vh' }} alt="XCel-files" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/json.webp')} style={{ width: '19vh' }} alt="json" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/xml.png')} style={{ width: '19vh' }} alt="xml" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/pdf.jpg')} style={{ width: '16vh' }} alt="pdf" />
                    </Row>
                </Container>
            </section>

            <section className="py-2">
                <Container className="image-video-co">
                    <Row className="text-center pt-3">
                        <h3 className="image-head">
                            Image and Videos
                        </h3>
                        <p>
                            Multimedia data from video files and streaming platforms, which can provide <br />
                            insights through visual content analysis and audience engagement metrics
                        </p>
                    </Row>
                    <Row className="image-vide-img-se py-3">
                        <img src={require('../assets/data-integration/DAtabase-Icons/image.png')} style={{ width: '18vh' }} alt="image-v" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/video.png')} style={{ width: '20vh' }} alt="video" />
                    </Row>
                </Container>
            </section>

            <section className="py-2">
                <Container className="apis-web">
                    <Row className="text-center  pt-3">
                        <h3 className="apis-web-ser-he">
                            APIs and Web Services
                        </h3>
                        <p>
                            Data from external applications and services accessed via APIs (Application Programming Interfaces),<br />
                            allowing integration with third-party systems and cloud services
                        </p>
                    </Row>
                    <Row className="py-4 text-center">
                        <p >
                            Data obtained from web services, including social media platforms and online <br />
                            surveys, which can enrich understanding of Customer behavior and market trends.
                        </p>
                    </Row>
                </Container>
            </section>

            <section className="py-2">
                <Container className="cloud-stora">
                    <Row className="text-center pt-3">
                        <h3 className="cloudstorage-hea">
                            Cloud Storage
                        </h3>
                        <p>
                            Data stored in cloud solutions (like AWS, Google Cloud, and Azure)<br />

                            that offers flexibility and scalability for integrating various data sources.
                        </p>
                    </Row>
                    <Row className="image-vide-img-se py-3">
                        <img src={require('../assets/data-integration/DAtabase-Icons/amazon.png')} style={{ width: '18vh' }} alt="amazon" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/google-cloud.png')} style={{ width: '20vh' }} alt="google-cloud" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/azure.png')} style={{ width: '20vh' }} alt="azure" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/onedrive.png')} style={{ width: '20vh' }} alt="onedrive" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/ftp.webp')} style={{ width: '18vh' }} alt="ftp" />
                    </Row>
                </Container>
            </section>

            <section className="pt-3 pb-3">
                <Container className="cloud-stora">
                    <Row className="text-center pt-3">
                        <h3 className="cloudstorage-hea">
                            SOcial media
                        </h3>
                        <p>
                            Data extraction from social media platforms like Facebook, YouTube, LinkedIn, and Twitter involves<br />
                            utilizing their APIs to gather relevant user profiles, posts, and engagement metrics
                        </p>
                    </Row>
                    <Row className="social-meida-img-sec py-3">
                    <img src={require('../assets/data-integration/DAtabase-Icons/linkedin.png')} style={{ width: '16vh' }} alt="linkedin" />
                    <img src={require('../assets/data-integration/DAtabase-Icons/facebook.png')} style={{ width: '16vh' }} alt="facebook" />
                    <img src={require('../assets/data-integration/DAtabase-Icons/instagram.png')} style={{ width: '16vh' }} alt="Instagram" />
                    <img src={require('../assets/data-integration/DAtabase-Icons/youtube.png')} style={{ width: '16vh' }} alt="youtube" />
                    <img src={require('../assets/data-integration/DAtabase-Icons/twitter.png')} style={{ width: '16vh' }} alt="twitter" />
                    </Row>
                </Container>
            </section>
        </div>
    )
}