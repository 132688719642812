import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import mediaicon from '../assets/Pricing/Img11.png';
import medisma from '../assets/Pricing/Img2.png';
import { Helmet } from "react-helmet";

const imageContentPairs = [

    {
        mainImage: [
            { image1: require('../assets/Media/Empower-Your-Business-with-SmartIG-Data-Visualization/Empower-Your-Business-with-SmartIGs-Data-Visualization-1.jpg') },
            { image2: require('../assets/Media/Empower-Your-Business-with-SmartIG-Data-Visualization/Empower-Your-Business-with-SmartIGs-Data-Visualization-2.jpg') },
            { image3: require('../assets/Media/Empower-Your-Business-with-SmartIG-Data-Visualization/Empower-Your-Business-with-SmartIGs-Data-Visualization-3.jpg') },
            { image4: require('../assets/Media/Empower-Your-Business-with-SmartIG-Data-Visualization/Empower-Your-Business-with-SmartIGs-Data-Visualization-4.jpg') },
            { image5: require('../assets/Media/Empower-Your-Business-with-SmartIG-Data-Visualization/Empower-Your-Business-with-SmartIGsData-Visualization-5.jpg') },
            { image6: require('../assets/Media/Empower-Your-Business-with-SmartIG-Data-Visualization/Empower-Your-Business-with-SmartIGs-Data-Visualization-6.jpg') },
            


        ],
        content: 'Empower Your Business with Smart IGs Data Visualization   ',
        description: 'Data Visualization is the key for organizations that want to utilize the full potential of data. ',
        description2: 'Our tool Smart IG is all about dynamic dashboards and live reporting. ',
        point1: '→ From C-suite Executives to team managers to team members, Smart IG empowers everyone to access the insights they need, tailored to their unique roles and goals. ',
        point2: '→ Easy to use drag and drop interface  ',
        point3: '→ Interactive & compelling dashboards and cards ',
        point4: '→ Create, edit, and download multiple charts with just a click.  ',
        point5: '→ Auto-suggested chart options based on data ',
        point6: '→ Multiple chart options and easy switching ',
        point7: '→ Transforms complex data into clear, actionable insights ',
        point8: '→ Reveals hidden patterns, trends, and correlations. ',
        point9: '→ Historical data analysis to forecast future trends ',
        point10: '',
    },

    {
        mainImage: [
            { image1: require('../assets/Media/If-youre-still-on-ECC-and-debating-the-move-to-SAP-S4HANA-consider-this/If-youre-still-on-ECC-debating-the-move-to-SAP-S4HANA-consider-this-1.jpg') },
            { image2: require('../assets/Media/If-youre-still-on-ECC-and-debating-the-move-to-SAP-S4HANA-consider-this/If-youre-still-on-ECC-debating-the-move-to-SAP-S4HANA-consider-this-2.jpg') },
            { image3: require('../assets/Media/If-youre-still-on-ECC-and-debating-the-move-to-SAP-S4HANA-consider-this/If-youre-still-on-ECC-debating-the-move-to-SAP-S4HANA-consider-this-3.jpg') },
            { image4: require('../assets/Media/If-youre-still-on-ECC-and-debating-the-move-to-SAP-S4HANA-consider-this/If-youre-still-on-ECC-debating-the-move-to-SAP-S4HANA-consider-this-4.jpg') },
            { image5: require('../assets/Media/If-youre-still-on-ECC-and-debating-the-move-to-SAP-S4HANA-consider-this/If-youre-still-on-ECC-debating-the-move-to-SAP-S4HANA-consider-this-5.jpg') },


        ],
        content: 'If you’re still on ECC and debating the move to SAP S4HANA, consider this.  ',
        description: '',
        description2: '',
        point1: '→  Reports project a 20-25% shortage in hashtag#SAP consultants by 2027. Don’t delay. Integrate Smart IG now for affordable and seamless migrations. ',
        point2: '→ As per reports, 93% of companies believe innovative technologies are essential for digital transformation. With integrated hashtag#AI, hashtag#IoT, and hashtag#ML capabilities, S4 HANA provides the necessary tools and infrastructure to support this journey. ',
        point3: '→ As per reports, 72% of companies that rely on in-house hashtag#Migration specialists face budget overruns and delays',
        point4: '→ Choose Smart IG, an SAP partner supporting hashtag#RISEwithSAP, to ensure 100% accurate data migrations with zero downtime. ',
        point5: '',
        point6: '',
        point7: '',
        point8: '',
        point9: '',
        point10: '',
    },

    {
        mainImage: [
            { image1: require('../assets/Media/What-are-you-migrating-to-cloud/What-Are-You-Migrating-to-the-Cloud-1.jpg') },
            { image2: require('../assets/Media/What-are-you-migrating-to-cloud/What-Are-You-Migrating-to-the-Cloud-2.jpg') },
            { image3: require('../assets/Media/What-are-you-migrating-to-cloud/What-Are-You-Migrating-to-the-Cloud-3.jpg') },
            { image4: require('../assets/Media/What-are-you-migrating-to-cloud/What-Are-You-Migrating-to-the-Cloud-4.jpg') },
            { image5: require('../assets/Media/What-are-you-migrating-to-cloud/What-Are-You-Migrating-to-the-Cloud-5.jpg') },
            { image6: require('../assets/Media/What-are-you-migrating-to-cloud/What-Are-You-Migrating-to-the-Cloud-6.jpg') },
            { image7: require('../assets/Media/What-are-you-migrating-to-cloud/What-Are-You-Migrating-to-the-Cloud-7.jpg') },
            { image8: require('../assets/Media/What-are-you-migrating-to-cloud/What-Are-You-Migrating-to-the-Cloud-8.jpg') },

        ],
        content: 'What Are You Migrating to the Cloud? ',
        description: 'Youre not just moving data; youre moving your business forward.',
        description2: 'Migrating to the cloud means:',
        point1: '→ Scalability & Flexibility – Adapt seamlessly to market changes and business needs.',
        point2: '→ Cost Efficiency – Reduce overhead and maximize ROI by eliminating outdated infrastructure.',
        point3: '→ Enhanced Security & Compliance – Protect your data and ensure compliance with the latest regulations. ',
        point4: '→Innovation at Scale – Tap into cutting-edge technologies like AI, ML, RPA, and AdvancedAnalytics.',
        point5: '',
        point6: '',
        point7: '',
        point8: '',
        point9: '',
        point10: '',
    },

    {
        mainImage: [
            { image1: require('../assets/Media/Explore-advanced/Explore-Advanced-Analytics-Through-SmartIG-1.jpg') },
            { image2: require('../assets/Media/Explore-advanced/Explore-Advanced-Analytics-Through-SmartIG-2.jpg') },
            { image3: require('../assets/Media/Explore-advanced/Explore-Advanced-Analytics-Through-SmartIG-3.jpg') },
            { image4: require('../assets/Media/Explore-advanced/Explore-Advanced-Analytics-Through-SmartIG-4.jpg') },


        ],
        content: 'Explore Advanced Analytics Through Smart IG ',
        description: 'Ready to elevate your data-driven decision-making?',
        description2: 'Discover how Smart IG unlocks #AdvancedAnalytics to turn raw data into actionable insights.',
        point1: '→ Gain access to predictive models, KPIs, and real-time dashboards',
        point2: '→ Predict trends, patterns, and correlations.',
        point3: '→ Optimize performance',
        point4: '→ Gain accurate, real-time, and AI-powered insights',
        point5: ' → Make proactive decisions',
        point6: '',
        point7: '',
        point8: '',
        point9: '',
        point10: '',
    },

    {
        mainImage: [
            { image1: require('../assets/Media/linkedin-daily/s-winnig-factors/5-winnig-factor-s1.png') },
            { image2: require('../assets/Media/linkedin-daily/s-winnig-factors/5-winnig-factor-s2.png') },
            { image3: require('../assets/Media/linkedin-daily/s-winnig-factors/5-winnig-factor-s3.png') },
            { image4: require('../assets/Media/linkedin-daily/s-winnig-factors/5-winnig-factor-s4.png') },
            { image5: require('../assets/Media/linkedin-daily/s-winnig-factors/5-winnig-factor-s5.png') },
            { image6: require('../assets/Media/linkedin-daily/s-winnig-factors/5-winnig-factor-s6.png') },
            { image7: require('../assets/Media/linkedin-daily/s-winnig-factors/5-winnig-factor-s7.png') },
            { image8: require('../assets/Media/linkedin-daily/s-winnig-factors/5-winnig-factor-s8.png') },


        ],
        content: '5 Winning Factors of Smart IG for Data Migration ',
        description: ' What makes Smart IG the best tool for Data Migration? ',
        description2: 'Here are the top 5 winning factors.',
        point1: '→ Customized for Complex Data',
        point2: '→ AI-Driven hashtag#Migration Planning',
        point3: '→ Zero Downtime ',
        point4: '→ Save Costs With Simplified Infrastructure',
        point5: '→ Post-Migration Support',
        point6: '',
        point7: '',
        point8: '',
        point9: '',
        point10: '',
    },

    {
        mainImage: [
            { image1: require('../assets/Media/c2/1.jpg') },
            { image2: require('../assets/Media/c2/2.jpg') },
            { image3: require('../assets/Media/c2/3.jpg') },
            { image4: require('../assets/Media/c2/4.jpg') },
            { image5: require('../assets/Media/c2/5.jpg') },
            { image6: require('../assets/Media/c2/6.jpg') },
            { image7: require('../assets/Media/c2/7.jpg') },
            { image8: require('../assets/Media/c2/8.jpg') },
            { image9: require('../assets/Media/c2/9.jpg') },
            { image10: require('../assets/Media/c2/10.jpg') },
        ],
        content: 'Struggling with Complex Data Integration? Data Silos? High Operational Costs?',
        description: 'SmartIG’s advanced BI and ETL solutions are here to save the day.',
        description2: 'Here are Common Pain Points',
        point1: '→ Data Silos',
        point2: '→ Data Quality Issues',
        point3: '→ Complex Data Integration ',
        point4: '→ Slow Data Processing',
        point5: '→ Limited Data Access',
        point6: '→ High Operational Costs',
        point7: '→ Lack of Real-Time Insights',
        point8: '',
        point9: '',
        point10: '',
    },


];

export const Linkedinposts = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [innerActiveIndex, setInnerActiveIndex] = useState(0);

    useEffect(() => {
        setInnerActiveIndex(0);
    }, [activeIndex]);

    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
        setInnerActiveIndex(0); // Reset inner carousel on main slide change
    };

    const handlePrev = () => {
        setActiveIndex(activeIndex === 0 ? imageContentPairs.length - 1 : activeIndex - 1);
        setInnerActiveIndex(0); // Reset inner carousel on main slide change
    };

    const handleNext = () => {
        setActiveIndex(activeIndex === imageContentPairs.length - 1 ? 0 : activeIndex + 1);
        setInnerActiveIndex(0); // Reset inner carousel on main slide change
    };

    const handleInnerPrev = () => {
        setInnerActiveIndex(innerActiveIndex === 0 ? imageContentPairs[activeIndex].mainImage.length - 1 : innerActiveIndex - 1);

    };

    const handleInnerNext = () => {
        setInnerActiveIndex(innerActiveIndex === imageContentPairs[activeIndex].mainImage.length - 1 ? 0 : innerActiveIndex + 1);
    };
    return (
        <div>

            <Helmet>
                <title>End-to-End Data Migration, Integration & Visualization  </title>

                <meta name="description" content="Smart Integraphics transforms data with end-to-end AI-powered migration, integration, cleaning, and visualization solutions for ultimate business value." />

                <meta name="author" content="Smart InteGraphics" />

                <meta name="robots" content="index, follow" />

                {/* Og Tags */}

                <meta property="og:title" content="End-to-End Data Migration, Integration & Visualization " />

                <meta property="og:description" content="Smart Integraphics transforms data with end-to-end AI-powered migration, integration, cleaning, and visualization solutions for ultimate business value." />

                {/* <meta property="og:image" content={homebanner} /> */}

                <meta property="og:url" content="https://smartintegraphics.com/linkedin" />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}

                <meta name="twitter:title" content="End-to-End Data Migration, Integration & Visualization " />

                <meta name="twitter:description" content="Smart Integraphics transforms data with end-to-end AI-powered migration, integration, cleaning, and visualization solutions for ultimate business value." />

                {/* <meta name="twitter:image" content={homebanner} /> */}
            </Helmet>

            <section className="linked-main-sec">
                <Container>
                    <Row className="text-center d-flex align-items-center justify-content-center media-hero">
                        <div className="media-main">
                            <p>MEDIA</p>
                        </div>
                        <p>
                            Unlock the business value of your data with an end-to-end tool that <br />
                            extracts, integrates, cleans and visualizes your data.
                        </p>

                    </Row>


                </Container>
            </section>

            <section style={{ height: '0px' }}>
                <Row style={{ height: '0px !important' }}>
                    <img src={mediaicon} className=" media-big-icon" alt="media-icon" />
                    <img src={medisma} className="medi-sma" alt="media" />
                </Row>
            </section>

            <section className="bg-light py-2">
                <Container>
                    <Row className="align-items-center justify-content-center">

                        <Col lg={4} className="mt-4" >
                            <div className="scio-outer">
                                <div className="scio-inner-ig">
                                    <img src={require('../assets/Media/26-11-2024.jpg')} className="w-100" alt="linkedin-2" />
                                </div>
                                <div className="scio-co-car ">
                                    <a href="https://www.linkedin.com/posts/integral-analytics-solutions_advancedanalytics-dataanalytics-datadrivendecisions-activity-7267148086634778625-hOvc?utm_source=share&utm_medium=member_desktop" target="blank" style={{ textDecoration: 'none', color: 'black' }}>
                                        <h4>
                                        Implementing Data Analytics in Marketing for Greater Impact! 

                                        </h4>
                                    </a>

                                    {/* <p>
                                        Overwhelmed by complex data and slow reporting? SmartIG simplifies it all for faster, accurate insights.
                                    </p> */}
                                </div>


                            </div>
                        </Col>

                        <Col lg={4} className="mt-4" >
                            <div className="scio-outer">
                                <div className="scio-inner-ig">
                                    <img src={require('../assets/Media/21-11-2024.jpg')} className="w-100" alt="linkedin-1" />
                                </div>
                                <div className="scio-co-car ">
                                    <a href="https://www.linkedin.com/posts/integral-analytics-solutions_analytics-dataanalytics-ai-activity-7265346187887599616-MPk0?utm_source=share&utm_medium=member_desktop" target="blank" style={{ textDecoration: 'none', color: 'black' }}>
                                        <h4>
                                            Let's Explore iMirAI Features One Step at a Time! Achieve Real-time & AI-driven insights
                                        </h4>
                                    </a>

                                    {/* <p>
                                        Whether you’re a business leader, a data enthusiast, or new to the world of Analytics, iMirAI makes it easy and intuitive.
                                    </p> */}
                                </div>


                            </div>
                        </Col>

                        <Col lg={4} className="mt-4" >
                            <div className="scio-outer">
                                <div className="scio-inner-ig">
                                    <img src={require('../assets/Media/18-11-2024.jpg')} className="w-100" alt="linkedin-1" />
                                </div>
                                <div className="scio-co-car ">
                                    <a href="https://www.linkedin.com/posts/integral-analytics-solutions_analytics-dataquality-datavisualization-activity-7263167614053421056-atLg?utm_source=share&utm_medium=member_desktop" target="blank" style={{ textDecoration: 'none', color: 'black' }}>
                                        <h4>
                                            Why Most Data Visualization Dashboards Fail?
                                        </h4>
                                    </a>

                                    {/* <p>
                                        Whether you’re a business leader, a data enthusiast, or new to the world of Analytics, iMirAI makes it easy and intuitive.
                                    </p> */}
                                </div>


                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>

            <section className="">
                <Container>
                    <Row className="carousel-main">
                        <p>
                            CAROUSAL
                        </p>
                    </Row>

                    <Row className="carousel-outer-bo mt-2">
                        <div className="image-content-section">
                            <Row className="align-items-center justify-content-center">
                                <Col lg={4}>
                                    <div className="carousel">
                                        <Carousel activeIndex={activeIndex} onSelect={handleSelect} indicators={false} >
                                            {imageContentPairs.map((pair, index) => (
                                                <Carousel.Item key={index}>
                                                    {/* Main Image Carousel */}
                                                    <div className="inner-ca-wi">
                                                        <Carousel indicators={false} activeIndex={innerActiveIndex} onSelect={setInnerActiveIndex} className="inner-carousel-sec">
                                                            {pair.mainImage.map((imgObj, idx) => {
                                                                const imgKey = `image${idx + 1}`;
                                                                return imgObj[imgKey] ? ( // Check if the image exists
                                                                    <Carousel.Item key={idx}>
                                                                        <img className="d-block w-100" src={imgObj[imgKey]} alt={`new-image-${idx + 1}`} />
                                                                    </Carousel.Item>
                                                                ) : null; // Render nothing if the image doesn't exist
                                                            })}
                                                        </Carousel>
                                                    </div>


                                                    <div className="carousel-controls">
                                                        <button onClick={handleInnerPrev} className="inner-carus-prev" disabled={pair.mainImage.length <= 1}>
                                                            <ArrowBackIcon className="aro-back" />
                                                        </button>
                                                        <button onClick={handleInnerNext} className="inner-carus-next" disabled={pair.mainImage.length <= 1}>
                                                            <ArrowForwardIcon className="aro-back" />
                                                        </button>
                                                    </div>

                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                    </div>
                                </Col>
                                <Col lg={6} className="mt-5 px-3">
                                    <div className="content">
                                        <h2 className="carousel-hed-co">{imageContentPairs[activeIndex].content}</h2>
                                        <p>{imageContentPairs[activeIndex].description}</p>
                                        <p>{imageContentPairs[activeIndex].description2}</p>
                                        <div className="py-3">
                                            {Array.from({ length: 10 }, (_, i) => (
                                                <p key={i}>
                                                    {imageContentPairs[activeIndex][`point${i + 1}`]}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="carousel-controls mt-3">
                                        <button onClick={handlePrev} className="linkid-prev-but">
                                            <div className="btn-inner">
                                                <ArrowBackIcon className="aro-back" />
                                                <p>Prev</p>
                                            </div>
                                        </button>
                                        <button onClick={handleNext} className="linked-next-but">
                                            <div className="btn-inner">
                                                <p>Next</p>
                                                <ArrowForwardIcon className="aro-back" />
                                            </div>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>



                    </Row>
                    <Row className="linked-botm-ico" style={{ height: '0px !important' }}>
                        <img src={require('../assets/Pricing/Img4.png')} className="w-25    " alt="linkedin-page-ico" />
                    </Row>

                </Container>
            </section>

        </div>
    )
}