import React from "react";
import SmartIGBroucherUpdated from '../assets/broucher/SmartIG-broucher-updated.pdf';
import './smartig.css';
import { Button, Col, Container, Row } from "react-bootstrap";

export const SmartIGBroucher = () => {
    const handleDownload = () => {
        

        const link = document.createElement('a');
        link.href = SmartIGBroucherUpdated;
        link.download = 'SmartIG-Brochure';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return(
        <>
         <section className="broch-smartig">
            <Container>
            <Row className="mt-5">
                        <Col>
                        
                            <Button type="submit" onClick={handleDownload}>
                                Download Brochure
                            </Button>
                        </Col>

                    </Row>
            </Container>
        </section>
        </>
    )
}