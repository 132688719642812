import React from "react";
import { Accordion, Container, Row, Col, Table, Button } from "react-bootstrap";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import './pricin2.css'
import { Link } from "react-router-dom";

export const Pricing2 = () => {
    return (
        <>
            <section className="price2-main-head-sec">
                <Container>
                    <Row className="text-center pricing-main">
                        <Row className="text-center align-items-center justify-content-center">

                            <div className="about-2">
                                <p>
                                    Price
                                </p>
                            </div>

                        </Row>
                        <h2 className="py-3">
                            Investment In Your Future
                        </h2>
                        <p>
                            Unlock the business value of your data with an end-to-end tool that extracts, <br /> integrates, cleans, and visualizes your data.
                        </p>
                    </Row>

                    {/* <Row className="empty-ic-1">
                        <img src={require('../assets/Pricing/Img17.png')} className="price-icon-pricing" alt="icon-1" />
                    </Row> */}
                </Container>
            </section>

            {/* Accordion Section with Unique Class Names */}
            <section className="mb-5">
                {/* <Container> */}
                <Row className="text-center my-5 ">
                    <h3 className="pricing-heading">Our Pricing Plans</h3>
                </Row>
                <Row>
                    <Col md={12}>
                        <Accordion defaultActiveKey="0" className="unique-accordion-container">
                            <Accordion.Item eventKey="0" className="unique-accordion-item">
                                <Accordion.Header className="unique-accordion-header"><h3> Data Integration </h3></Accordion.Header>
                                <Accordion.Body className="unique-accordion-body">
                                    <Table bordered hover>
                                        <thead>
                                            <tr className="text-center table-head-price">
                                                <th></th>
                                                <th>BASIC <br />$49.99/$65.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    1 User
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Buy Now
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </th>
                                                <th>PROFESSIONAL <br />$79.99/$99.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    3 Users
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Buy Now
                                                            </Button>
                                                        </Link>
                                                    </div></th>
                                                <th>ENTERPRISE
                                                    (On-Cloud)<br />

                                                    $99.99/$119.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    10 Users
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="-https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Buy Now
                                                            </Button>
                                                        </Link>
                                                    </div></th>

                                                    <th>ENTERPRISE
                                                    (On-Premise)<br />

                                                    $99.99/$119.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    10 Users
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Contact Sales
                                                            </Button>
                                                        </Link>
                                                    </div></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={5} className="main-head-table-sub-sec">Files</td>

                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">XLS</td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">XLSX</td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">
                                                    CSV
                                                </td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">JSON</td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td colSpan={5} className="main-head-table-sub-sec">DataBase</td>

                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Oracle</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">MS SQL</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr"> MY SQL</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">IBM DB2</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Maria Database</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Ms Access</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">SQL Lite</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">SQL Server</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Postgre SQL</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">Mongo DB</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">IBM netezza</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Teradata</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Azure SQL</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td colSpan={5} className="main-head-table-sub-sec">ERP</td>

                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">SAP ECC</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">SAP HANA</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Oracle ERP</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">MS Dynamics</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={5} className="main-head-table-sub-sec">Online Services</td>

                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">X</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Youtube</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Facebook</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">LinkedIn</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1" className="unique-accordion-item">
                                <Accordion.Header className="unique-accordion-header"><h3>Data Migration </h3></Accordion.Header>
                                <Accordion.Body className="unique-accordion-body">
                                    <Table bordered hover>
                                        <thead>
                                            <tr className="text-center table-head-price">
                                                <th></th>
                                                <th>BASIC <br />$49.99/$65.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    1 User
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Buy Now
                                                            </Button>
                                                        </Link>
                                                    </div></th>
                                                <th>PROFESSIONAL <br />$79.99/$99.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    3 Users
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Buy Now
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </th>
                                                <th>ENTERPRISE
                                                    (On-Cloud)<br />
                                                    $99.99/$119.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    10 Users
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Buy Now
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </th>

                                                <th>ENTERPRISE
                                                    (On-Premise)<br />
                                                    $99.99/$119.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    10 Users
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Contact Sales
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td className="sub-tit-table-integr">SAP ECC</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">SAP HANA</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">
                                                    Oracle ERP
                                                </td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">MS Dynamics</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>


                                            <tr>
                                                <td className="sub-tit-table-integr">Oracle</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">MS SQLL</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr"> MY SQL</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">IBM DB2</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Maria Database</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Ms Access</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">SQL Lite</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">SQL Server</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Postgre SQL</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">Mongo DB</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">IBM netezza</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Teradata</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Azure SQL</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>


                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2" className="unique-accordion-item">
                                <Accordion.Header className="unique-accordion-header"><h3> Data Analytics </h3></Accordion.Header>
                                <Accordion.Body className="unique-accordion-body">
                                    <Table bordered hover>
                                        <thead>
                                            <tr className="text-center table-head-price">
                                                <th></th>
                                                <th>BASIC <br />$49.99/$65.99<br />
                                                    Per User<br />
                                                    (Year/Month)  1 User

                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Buy Now
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </th>
                                                <th>PROFESSIONAL <br />$79.99/$99.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    3 Users
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Buy Now
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </th>
                                                <th>ENTERPRISE
                                                    (On-Cloud)<br />
                                                    $99.99/$119.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    10 Users
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise" target="blank">
                                                            <Button className="price-buy-now" >
                                                               Buy Now
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </th>

                                                <th>ENTERPRISE (On-Premise)<br />
                                                    $99.99/$119.99<br />
                                                    Per User<br />
                                                    (Year/Month)
                                                    10 Users
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                            <Button className="price-buy-now" >
                                                                Contact Sales
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td className="sub-tit-table-integr">Charts</td>
                                                <td className="inner-tick-ico">16</td>
                                                <td className="inner-tick-ico">80</td>
                                                <td className="inner-tick-ico">200</td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">DashBoards</td>
                                                <td className="inner-tick-ico">2</td>
                                                <td className="inner-tick-ico">10</td>
                                                <td className="inner-tick-ico">25</td>
                                            </tr>


                                            <tr>
                                                <td className="sub-tit-table-integr">
                                                    Data pre-processing
                                                </td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Auto Suggestion Charts (AI Driven)</td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>


                                            <tr>
                                                <td className="sub-tit-table-integr">Download Dashboard as PDF</td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Refresh Rate</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico">Hourly</td>
                                                <td className="inner-tick-ico">As Nedded</td>
                                                <td className="inner-tick-ico">As Nedded</td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">iMirAI[co-pilot]</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Advanced Data Preparation</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Data Quering</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Personalized dashboards</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            {/* <tr>
                                                    <td className="sub-tit-table-integr">Defying correlation</td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                    <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                </tr> */}
                                            <tr>
                                                <td className="sub-tit-table-integr">Fine tune on Built-in Algorithms</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">Drill Down and Customize Charts</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Self-service Analytics</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">AI Generated Trends</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Vice-Activated Querying</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Smart Data Discovering</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Automated Insights</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                            <tr>
                                                <td className="sub-tit-table-integr">Visualization From Insights</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">Natural Language Questions</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">Contexual Analysis</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">Predictive Analytics</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">AI Predictive Analytics</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">Mail Notification</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>

                                            <tr>
                                                <td className="sub-tit-table-integr">Mobile App</td>
                                                <td className="inner-tick-ico">  <ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><ClearIcon className="cler-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                                <td className="inner-tick-ico"><CheckIcon className="correct-ico-price" /></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                {/* </Container> */}
            </section>



        </>
    );
};
