import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import './Home.css';
import { Link } from "react-router-dom";

// import homebanner from '../assets/Home/Home-Banner4.jpg';
import dataintegration from '../assets/Home/Data-Integration.png';
import datamigration from '../assets/Home/Data-Migration.png';
import dataanalytics from '../assets/Home/Data-Analytics.png';
import aboutus from '../assets/Home/about-screen-shot.png';
import mediaarticle from '../assets/Home/Media-Articles-Image.jpg';
import linkedin from '../assets/Home/LinkedinPost-Image.jpg';
import youtube from '../assets/Home/YouTube-Image-home.jpg';
import { Helmet } from "react-helmet";


export const Home = () => {
    return (

        <div style={{ marginTop: '-3rem' }}>

            <Helmet>
                <title> AI-powered Data Analytics Tool | Data Visualization Solution. </title>

                <meta name="description" content="Smart Integraphics, a leading provider of AI-powered Data Analytics & Visualization solutions, offers expertise in Data Integration, Migration, and Analytics." />

                <meta name="author" content="Smart InteGraphics" />

                <meta name="robots" content="index, follow" />

                {/* Og Tags */}

                <meta property="og:title" content="AI-powered Data Analytics Tool | Data Visualization Solution." />

                <meta property="og:description" content="Smart Integraphics, a leading provider of AI-powered Data Analytics & Visualization solutions, offers expertise in Data Integration, Migration, and Analytics." />

                {/* <meta property="og:image" content={homebanner} /> */}

                <meta property="og:url" content="https://smartintegraphics.com/" />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}

                <meta name="twitter:title" content="AI-powered Data Analytics Tool | Data Visualization Solution." />

                <meta name="twitter:description" content="Smart Integraphics, a leading provider of AI-powered Data Analytics & Visualization solutions, offers expertise in Data Integration, Migration, and Analytics." />

                {/* <meta name="twitter:image" content={homebanner} /> */}
            </Helmet>

            <section>
                <div className="main-hero-sec-co">
                    {/* <Container> */}
                        <Row className="main-hero-co-new" style={{ paddingTop: '6rem', marginLeft:'6rem' }}>
                            <Col lg={8}>
                                <div className="main-pafe-softwear">
                                    <p>
                                        SOFTWARE
                                    </p>
                                </div>
                                <h1 className="main-home-page-what-smart">
                                    What is <br />
                                    <span className="main-home-page-smart-ig-big"> Smart InteGraphics?</span>
                                </h1>
                                <p className="py-3 " style={{ fontWeight: '500', fontSize: '18px' }}>
                                    We are a leading AI-powered Data Analytics &<br /> Data Visualization Solution Providers.
                                </p>

                                <div className="d-flex align-items-start py-3 " style={{ gap: '50px' }}>
                                    <Link to="https://calendly.com/customerdemos/smartig_mirai?month=2024-09" style={{ textDecoration: 'none' }}>
                                        <button className="about-btn-2">
                                            <div className="about-di-2">
                                                <img src={require('../assets/Home/Book a Demo.png')} className="w-75" alt="pharma" />
                                            </div> <div className=""><p>BOOK A DEMO</p></div></button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    {/* </Container> */}

                </div>
            </section>

            {/* <section className="hero-sec">
                <Row className="hero-baner-main">
                    <img src={homebanner} className="her-ina-main" alt="banner" loading="Lazy" />
                </Row>

                <Row className="banner-test-co">

                    <Col lg={6} className="text-start">
                        <div className="Softwear mt-5">
                            <p>
                                SOFTWARE
                            </p>
                        </div>
                        <h1 className="smart-g-what">
                            What is <br />
                            <span className="banner-content-smartig"> Smart InteGraphics?</span>
                        </h1>

                        <p className="py-3 " style={{ fontWeight: '500', fontSize: '18px' }}>
                            We are a leading AI-powered Data Analytics &<br /> Data Visualization Solution Providers.
                        </p>

                        <div className="d-flex align-items-start py-3 " style={{ gap: '50px' }}>
                           


                            <Link to="https://calendly.com/customerdemos/smartig_mirai?month=2024-09" style={{ textDecoration: 'none' }}>
                                <button className="about-btn-2">
                                    <div className="about-di-2">
                                        <img src={require('../assets/Home/Book a Demo.png')} className="w-75" alt="pharma" />
                                    </div> <div className=""><p>BOOK A DEMO</p></div></button>
                            </Link>


                        </div>
                    </Col>


                </Row>

            </section> */}

            {/* <section className="main-hero-sec-2">
                <Container>
                    <Row className="banner-test-co-2">

                        <Col lg={5} className="second-main-head">
                            <div className="Softwear-2 mt-5">
                                <p>
                                    SOFTWARE
                                </p>
                            </div>
                            <h1 className="smart-g-what-2">
                                What is
                                <span className="banner-content-smartig-2"> Smart InteGraphics?</span>
                            </h1>

                            <p className="py-4" style={{ fontWeight: '500' }}>
                                We are a leading AI-powered Data Analytics &<br /> Data Visualization Solution Providers.
                            </p>

                            <div className="d-flex align-items-start mt-4 " style={{ gap: '50px' }}>

                                <Link to="https://calendly.com/customerdemos/smartig_mirai?month=2024-09" style={{ textDecoration: 'none' }}>
                                    <button className="about-btn-2-1">
                                        <div className="about-di-2-1">
                                            <img src={require('../assets/Home/Book a Demo.png')} className="w-75" alt="pharma" />
                                        </div> <div className=""><p>BOOK A DEMO</p></div></button>
                                </Link>


                            </div>
                        </Col>


                    </Row>
                </Container>
            </section> */}

            <section className="mt-3">
                <Container className="industeries">
                    <Row className="text-center">
                        <p className="ind-head">
                            Industries that love our product
                        </p>
                    </Row>

                    <Row className="align-items-center justify-content-center mt-3 industries-our-product">
                        <Col lg={2} sm={4} xs={6}>
                            <div className="industr-inner">
                                <img src={require('../assets/Home/Industrys-Icons-01.png')} alt="pharma" loading="lazy" />
                                <p>
                                    Pharma
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} sm={4} xs={6} >
                            <div className="industr-inner">
                                <img src={require('../assets/Home/Industrys-Icons-02.png')} alt="petrochemical" loading="lazy" />
                                <p>
                                    Petrochemical
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} sm={4} xs={6} >
                            <div className="industr-inner">
                                <img src={require('../assets/Home/Industrys-Icons-03.png')} alt="oil and gas" loading="lazy" />
                                <p>
                                    Oil & Gas
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} sm={4} xs={6}>
                            <div className="industr-inner">
                                <img src={require('../assets/Home/Industrys-Icons-04.png')} alt="mining" loading="lazy" />
                                <p>
                                    Mining
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} sm={4} xs={6}>
                            <div className="industr-inner">
                                <img src={require('../assets/Home/Industrys-Icons-05.png')} alt="health" loading="lazy" />
                                <p>
                                    Health
                                </p>
                            </div>
                        </Col>

                        <Col lg={2} sm={4} xs={6}>
                            <div className="industr-inner">
                                <img src={require('../assets/Home/Industrys-Icons-06.png')} alt="consulting" loading="lazy" />
                                <p>
                                    Consulting
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} sm={4} xs={6}>
                            <div className="industr-inner">
                                <img src={require('../assets/Home/Industrys-Icons-07.png')} alt="Banking" loading="lazy" />
                                <p>
                                    Banking
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} sm={4} xs={6}>
                            <div className="industr-inner">
                                <img src={require('../assets/Home/Industrys-Icons-08.png')} alt="Retail" loading="lazy" />
                                <p>
                                    Retail
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} sm={4} xs={6}>
                            <div className="industr-inner">
                                <img src={require('../assets/Home/Industrys-Icons-09.png')} alt="Manfacturing" loading="lazy" />
                                <p>
                                    Manufacturing
                                </p>
                            </div>
                        </Col>

                    </Row>



                </Container>
            </section>


            <section className="py-3">
                <Container>
                    <Row className="text-center align-items-center justify-content-center ">
                        <Row className="align-items-center justify-content-center">

                            <div className="Solution ">
                                <p>
                                    SOLUTIONS
                                </p>
                            </div>

                        </Row>

                        {/* <h2 className="fw-bold">What is <span className="about-smart"> SmartIG?</span> </h2> */}
                        <p style={{ fontWeight: '500' }}>
                            Smart IG is a portfolio of three embedded solutions namely,<br /> Data Integration, Data Migration and Data Analytics
                        </p>
                    </Row>

                    <Row className="mt-3 ">
                        <Col lg={4} className="mt-3">
                            <div className="solutions-card">
                                <div className="solu-card-img-1">
                                    <img src={dataintegration} className="w-75" alt="data-integration" loading="lazy" />
                                </div>
                                <h3 style={{fontSize:'1.9rem', fontWeight:'700'}}>
                                    Data Integration
                                </h3>
                                <p className="py-1 " >
                                    Extract, Connect, Integrate your Data
                                    from any source, at lightning speed.
                                </p>

                                <div className="d-flex align-items-center justify-content-center  " >
                                    <Link to='data-integration' style={{ textDecoration: 'none' }}>
                                        <button className="solution-btn-1"> TRY NOW</button>
                                    </Link>

                                    {/* <button className="solution-btn-2"> Watch Videos</button> */}
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} className="mt-3">
                            <div className="solutions-card">
                                <div className="solu-card-img" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <img src={datamigration} className="w-75" alt="data-migration" style={{ marginTop: '25px' }} loading="lazy" />
                                </div>
                                <h3 style={{fontSize:'1.9rem', fontWeight:'700'}}>
                                    Data Migration
                                </h3>
                                <p className="py-1" >
                                Migrate your data with precision and speed, ensuring seamless transition and zero downtime.
                                </p>

                                <div className="d-flex align-items-center justify-content-center " style={{ gap: '10px' }}>
                                    <Link to='data-migration' style={{ textDecoration: 'none' }}>
                                        <button className="solution-btn-1"> TRY NOW</button>
                                    </Link>

                                    {/* <button className="solution-btn-2"> Watch Videos</button> */}
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} className="mt-3">
                            <div className="solutions-card">
                                <div className="solu-card-img" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <img src={dataanalytics} className="w-75 " alt="data-analytics" style={{ marginTop: '25px' }} loading="lazy" />
                                </div>
                                <h3 style={{fontSize:'1.9rem', fontWeight:'700'}}>
                                    Data Analytics
                                </h3>
                                <p className="py-1" >
                                    The power of data analytics at your fingertips.
                                    Simple. Intuitive. Customizable
                                </p>

                                <div className="d-flex align-items-center justify-content-center  " style={{ gap: '10px' }}>
                                    <Link to='data-analytics' style={{ textDecoration: 'none' }}>
                                        <button className="solution-btn-1"> TRY NOW</button>
                                    </Link>

                                    {/* <button className="solution-btn-2"> Watch Videos</button> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className=" mt-2 about-sec">
                <Container>
                    <Row className="align-items-start justify-content-center">
                        <Col lg={5} className="align-items-center justify-content-center d-flex">
                            <img src={aboutus} className="w-75" alt="about-us" loading="lazy" />
                        </Col>
                        <Col lg={7} style={{ paddingTop: '55px' }}>
                            <div className="about-main">
                                <p>
                                    ABOUT-US
                                </p>
                            </div>
                            <h3 className="fw-bold">Smart InteGraphics <span className="about-smart"></span> </h3>
                            <p className="mt-5 about-conte">
                                We are Smart InteGraphics, and we have a passion for transforming how businesses use information and data to achieve success. With a focus on excellence and a love to invent, we help businesses succeed in the age of data-driven innovation using AI.Organizations can integrate data from disparate resources, create interactive and compelling dashboards, capture KPIs and insights, and share reports with the blend of advanced data analytics and visualization features backed by AI and ML.  
                            </p>
                            {/* <p  className="mt-2 about-conte">
                            Smart Integraphics (Smart IG), a leading Data Analytics tool, empowers organizations to thrive in the age of data-driven innovation powered by AI. It transforms how businesses leverage information and data to drive success. Smart IG seamlessly combines data migration, integration, and analytics, delivering advanced insights for smarter decision-making and sustainable growth.
                            </p> */}
                            {/* <div className="d-flex align-items-start mt-5 " style={{ gap: '50px' }}>
                                <button className="about-btn-1"> <div className="about-di-1">
                                    <img src={require('../assets/Home/Buy Now-Icon.png')} className="w-75" alt="buy now" />
                                </div> <div className=""><p>BUY NOW</p></div></button>
                                <button className="about-btn-2"> <div className="about-di-2">
                                    <img src={require('../assets/Home/Book a Demo.png')} className="w-50" alt="book a demo" />
                                </div> <div className=""><p>BOOK A DEMO</p></div></button>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="">
                <Row className="article-outer align-items-center justify-content-center ">
                    <div className="text-center mb-4">
                        <div className="Solution ">
                            <p>
                                MEDIA
                            </p>
                        </div>

                        <h2>
                            Articles
                        </h2>

                        <p style={{ fontWeight: '500' }}>
                            Explore our curated collection of articles, insights, LinkedIn posts, and YouTube videos, <br />keeping you updated on the latest data analytics strategies and industry trends.
                        </p>
                    </div>



                    <Col lg={3} className="px-4">
                        <div className="inner-article">
                            <img src={mediaarticle} className="w-100 " alt="blog-home" loading="lazy" />
                        </div>
                        <Link to='article' className="media-sco">
                            <h3 className="fw-bold py-2 mt-3 ">
                                Media Articles
                            </h3>
                        </Link>
                        <p className="media-cot-p">
                            Discover our media articles that showcase industry insights, expert perspectives, and innovative solutions in data analytics. Stay informed with our latest content.
                        </p>
                    </Col>

                    <Col lg={3} className="px-4">
                        <div className="inner-article">
                            <img src={linkedin} className="w-100 " alt="linkedin-post" loading="lazy" />
                        </div>

                        <Link to='linkedin' className="media-sco">
                            <h3 className="fw-bold py-2 mt-3">
                                Linkedin Post
                            </h3>
                        </Link>

                        <p className="media-cot-p" >
                            Follow our LinkedIn page for the latest updates, thought leadership, and discussions on data analytics trends and best practices.
                        </p>
                    </Col>

                    <Col lg={3} className="px-4">
                        <div className="inner-article">
                            <img src={youtube} className="w-100 " alt="youtube-home" loading="lazy" />
                        </div>

                        <Link to='youtube-videos' className="media-sco">
                            <h3 className="fw-bold py-2 mt-3">
                                Youtube Post
                            </h3>
                        </Link>

                        <p className="media-cot-p" >
                            Check out our YouTube channel for informative content, feature upgrades, tutorials, and expert interviews that explore the latest in data analytics.
                        </p>
                    </Col>

                </Row>

            </section>

            <section className="py-2 mb-4">
                <Row className="text-center py-5">
                    <h2 style={{ fontSize: '2.5rem' }} >
                        FAQ's
                    </h2>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col lg={12}>
                        <Accordion defaultActiveKey="" className="home-acc">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><h4 className="accord-head px-5">What Is Smart IG?</h4></Accordion.Header>
                                <Accordion.Body>
                                    Smart IG is a powerful platform that combines data migration, data integration, and data analytics into a single solution. It provides users with insightful visualizations and is supported by its AI-powered copilot, iMirAI, which enhances decision-making and user experience.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><h4 className="accord-head px-5">Cloud vs On-premise: How to choose the right plan?</h4></Accordion.Header>
                                <Accordion.Body>
                                    The decision hinges on several factors. Evaluate your organization's priorities in terms of security, cost, scalability, and maintenance to make an informed decision.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><h4 className="accord-head px-5">Is customization available for the on-cloud Smart IG?</h4></Accordion.Header>
                                <Accordion.Body>
                                    No. Only the Enterprise On-cloud model will be customizable.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header><h4 className="accord-head px-5">Is my data secure on the Smart IG cloud platform?</h4></Accordion.Header>
                                <Accordion.Body>
                                    Yes, your data is 100% secure. We implement robust security measures, such as encryption, regular security audits, and compliance with industry standards, to ensure the safety and confidentiality of your data.
                                </Accordion.Body>
                            </Accordion.Item>




                            <Accordion.Item eventKey="4">
                                <Accordion.Header><h4 className="accord-head px-5">User Wants to quit - how Subscription fee is going to be refunded?</h4></Accordion.Header>
                                <Accordion.Body>
                                    Amount fee will be refunded into the Bank account within 3 working days
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                                <Accordion.Header><h4 className="accord-head px-5">Will my data be deleted from the cloud after my subscription expires?</h4></Accordion.Header>
                                <Accordion.Body>
                                    Yes, all data will be provided in XLSX files and will be deleted 30 days after the subscription expiration date.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>

                </Row>

                <Row>

                </Row>
            </section>


        </div>
    )
}